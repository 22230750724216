// @ts-nocheck
import { useState, useEffect, useLayoutEffect } from "react";
import * as d3 from "d3";
import axios from "axios";
import Draggable from "react-draggable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import BgImg from "../assets/bg.png"
import { useNavigate } from "react-router-dom";

export default function BubbleScene(props) {
    const [width, setWidth] = useState(1920);
    const [height, setHeight] = useState(1080);
    const [walletData, setWalletData] = useState();
    const [showData, setShowData] = useState();
    const [selectedWallet, setSelectedWallet] = useState("");
    const [selectedAmount, setSelectedAmount] = useState("");
    const [selectedRank, setSelectedRank] = useState();
    const [showWalletList, setShowWalletList] = useState(true);
    const [selectedWalletShow, setSelectedWalletShow] = useState(false);
    const [copied, setCopied] = useState(false);
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [second, setSecond] = useState(0);
    const navigate = useNavigate();
    const [selectedListItem, setSelectedListItem] = useState({
        index: -1,
        flag: false,
    });

    // const [xScale, setXScale] = useState();
    // const [yScale, setYScale] = useState();
    // const [rScale, setRScale] = useState();
    const getWidth = () => {
        return (
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
        );
    };

    const getHeight = () => {
        return (
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight
        );
    };

    let data = [];

    useEffect(() => {
        const setResponsiveness = () => {
            setWidth(getWidth());
            setHeight(getHeight());
            console.log("🚀 ~ setResponsiveness ~ getHeight():", getHeight());
            console.log("🚀 ~ setResponsiveness ~ getWidth():", getWidth());
        };
        setResponsiveness();

        window.addEventListener("resize", setResponsiveness);
    }, [])
    setInterval(function time() {
        var d = new Date();
        var hours = 24 - d.getHours();
        var min = 60 - d.getMinutes();
        if ((min + '').length == 1) {
            min = '0' + min;
        }
        var sec = 60 - d.getSeconds();
        if ((sec + '').length == 1) {
            sec = '0' + sec;
        }
        setHour(hours)
        setMinute(min);
        setSecond(sec);
        console.log(hours + ':' + min + ':' + sec)
    }, 1000);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         if (remainedTime > 0) {
    //             let tmphour = Math.floor((remainedTime % 864e5) / 36e5)
    //             let tmpmin = Math.floor(((remainedTime % 864e5) % 36e5) / 6e4)
    //             let tmpsec = Math.floor(((remainedTime % 864e5) % 36e5) % 6e4 / 1000);
    //             setHour(tmphour)
    //             setMinute(tmpmin)
    //             setSecond(tmpsec)
    //             setRemainedTime(remainedTime - 1000)
    //         }
    //     }, 1000);
    // });

    useEffect(() => {

        const getWalletInfo = async () => {
            var result = await axios.get("https://ufo.aircoins.io/api");
            result.data.splice(0, 4);
            console.log("🚀 ~ getWalletInfo ~ result:", result);
            if (result.status == 200) {
                setWalletData(result.data);
                let numItems = result.data.length;
                let data = [];
                for (let i = 0; i < numItems; i++) {
                    data.push({
                        x: Math.random(),
                        y: Math.random(),
                        r: 15 + (result.data[i].amount / result.data[0].amount) * 100,
                        amount: result.data[i].amount,
                        address: result.data[i].address,
                        rank: i + 1
                    });
                }
                setShowData(data);

                const svg = d3
                    .select("#circle-content")
                    .append("svg")
                    .attr("width", width)
                    .attr("height", height);
                // .attr('x', 0)
                // .attr('y', 0)
                // .attr('width', 7)
                // .attr('height', 9);

                // const defs = svg.append('svg:defs');
                // defs
                //     .append('svg:pattern')
                //     .attr('id', 'tile-ww')
                //     .attr('patternUnits', 'userSpaceOnUse')
                //     .attr('width', '6')
                //     .attr('height', '6')
                //     .append('svg:image')
                //     .attr('xlink:href', '../assets/bg.png')
                //     .attr('x', 0)
                //     .attr('y', 0)
                //     .attr('width', 6)
                //     .attr('height', 6);

                const node = svg
                    // .append("g")
                    .selectAll("circle")
                    .data(data)
                    .join("circle")

                    .attr("r", function (d) {
                        return d.r
                    })
                    .attr("cx", getWidth() / 2)
                    .attr("cy", getHeight() / 2)
                    .style("fill", "url(#img1)")
                    // .style("fill-opacity", 0.3)
                    // .attr("stroke", "#b3a2c8")
                    // .style("stroke-width", 4)
                    .call(
                        d3
                            .drag() // call specific function when circle is dragged
                            .on("start", dragstarted)
                            .on("drag", dragged)
                            .on("end", dragended)
                    );

                // Features of the forces applied to the nodes:
                const simulation = d3
                    .forceSimulation()
                    .force(
                        "center",
                        d3
                            .forceCenter()
                            .x(getWidth() / 2)
                            .y(getHeight() / 2)
                    ) // Attraction to the center of the svg area
                    .force("charge", d3.forceManyBody().strength(1)) // Nodes are attracted one each other of value is > 0
                    .force(
                        "collide",
                        d3.forceCollide().strength(0.1).radius(30).iterations(1)
                    ); // Force that avoids circle overlapping

                // Apply these forces to the nodes and update their positions.
                // Once the force algorithm is happy with positions ('alpha' value is low enough), simulations will stop.
                simulation.nodes(data).on("tick", function (d) {
                    node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
                });

                // What happens when a circle is dragged?
                function dragstarted(event, d) {
                    if (!event.active) simulation.alphaTarget(0.03).restart();
                    d.fx = d.x;
                    d.fy = d.y;
                    setCopied(false);
                    setSelectedWallet(d.address);
                    setSelectedAmount(d.amount);
                    setSelectedWalletShow(true);
                    setSelectedRank(d.rank);


                }
                function dragged(event, d) {
                    d.fx = event.x;
                    d.fy = event.y;
                }
                function dragended(event, d) {
                    if (!event.active) simulation.alphaTarget(0.03);
                    d.fx = null;
                    d.fy = null;
                }
            }
            return result;
        };
        let wData = getWalletInfo();
    }, []);

    let maxRadius = 40;

    let xScale = d3.scaleLinear().domain([0, 1]).range([0, width]);
    let yScale = d3.scaleLinear().domain([0, 1]).range([0, height]);
    let rScale = d3.scaleLinear().domain([0, 1]).range([0, maxRadius]);



    const goToHome = () => {
        navigate("/")
    }

    return (
        <div className="h-screen" style={{ fontFamily: "Consolas" }}>

            <div div className="w-full flex flex-row bg-[#110b1a]" >
                {selectedWalletShow && (
                    <div className={`absolute w-[250px] h-[170px] rounded-[20px] bg-[#1d1921] top-[70px] left-[10px] p-4`}>
                        <div className="flex  flex-row items-center justify-between">
                            <p className="text-[20px]  text-white font-bold">
                                Selected Wallet
                            </p>
                            <button
                                className="text-[20px] text-gray-600 hover:text-white active:text-gray-600 font-bold"
                                onClick={() => {
                                    setSelectedWalletShow(false);
                                }}
                            >
                                ×
                            </button>
                        </div>
                        <div className="inline-flex items-center gap-3">
                            <a
                                href={`https://solscan.io/account/${selectedWallet}`}
                                className="text-[#f11] text-[14px] hover:underline hover:cursor-pointer"
                            >
                                {selectedWallet.slice(0, 4) +
                                    "..." +
                                    selectedWallet.substr(selectedWallet.length - 3)}
                            </a>
                            <CopyToClipboard
                                text={selectedWallet}
                                onCopy={() => setCopied(true)}
                            >
                                <button>📋</button>
                            </CopyToClipboard>
                            {copied ? (
                                <span style={{ color: "purple", fontSize: "12px" }}>
                                    Copied.
                                </span>
                            ) : null}
                        </div>
                        <div className="text-white text-[15px]">
                            Wallet Rank :
                            <span className="font-bold text-[15px]">#{selectedRank}</span>
                        </div>
                        <div className="text-white text-[15px]">
                            $Alien: {selectedAmount}
                        </div>
                        <button onClick={goToHome} className="w-full rounded-[4px] border-[1px] px-[27px]">
                            <p className="text-[#fff]">
                                Home
                            </p>
                        </button>
                        <div className="text-white text-[15px]">
                            Next Airdrop: {hour + ":" + minute + ":" + second}
                        </div>
                    </div>
                )
                }
                <svg className={`${showWalletList ? "hidden" : ""} md:block`} id="circle-content" width={width} height={height}>
                    <defs>
                        <pattern
                            id="img1"
                            height="100%"
                            width="100%"
                            patternContentUnits="objectBoundingBox"
                        >
                            <image
                                xlinkHref={BgImg}
                                preserveAspectRatio="none"
                                width="1"
                                height="1"
                            />
                        </pattern>
                    </defs>
                    {/* {showData?.map((d, index) => (
                        <Draggable key={index}>
                            <circle
                                cx={xScale(d.x)}
                                cy={yScale(d.y)}
                                r={rScale(d.r)}
                                stroke={"#fff"}
                                strokeWidth={"2px"}
                                onClick={() => {
                                    setCopied(false);
                                    setSelectedWallet(d.address);
                                    setSelectedAmount(d.amount);
                                    setSelectedRank(index + 1);
                                    setSelectedWalletShow(true);
                                    setSelectedListItem({ index: index, flag: true });
                                    updateTransitions()
                                }}
                                fill={"#8b47e0aa"}
                                className={`${selectedListItem.index == index && selectedListItem.flag
                                    ? "item-active"
                                    : ""
                                    }`}
                            />
                        </Draggable>
                    ))} */}
                </svg>
                {
                    showWalletList ? (
                        <div className="w-full md:w-[30%] overflow-y-auto h-screen shadow-2xl shadow-[#e91ed6] border-7 bg-gray-900">
                            {
                                <div className="flex flex-col">
                                    <div className="flex justify-between bg-[#111] px-2">
                                        <p className="text-[20px] p-4 text-white font-bold">
                                            Wallet Lists
                                        </p>
                                        <button
                                            className="text-[20px] p-4 bg-slate-950 text-gray-400 font-bold hover:text-white active:gray-400"
                                            onClick={() => setShowWalletList(false)}
                                        >
                                            ×
                                        </button>
                                    </div>
                                    <div className="m-3">
                                        <input
                                            className="w-full py-1 px-3 rounded-xl bg-gray-900 text-white border-2 border-[#db6bcd] focus:border-[#d31ea3]"
                                            placeholder="Search Wallets"
                                        ></input>
                                    </div>
                                </div>
                            }
                            <div className="flex flex-col">
                                {walletData?.map((item, index) => {
                                    return (
                                        <div
                                            className={`flex flex-row text-white text-[15px] font-bold hover:bg-[#442f4f] hover:cursor-pointer px-5 py-2 ${selectedListItem.index == index && selectedListItem.flag
                                                ? "bg-[#e91ed6] hover:bg-[#e91ed6]"
                                                : ""
                                                }`}
                                            key={index}
                                            onClick={() => {
                                                setCopied(false);
                                                setSelectedWallet(item.address);
                                                setSelectedAmount(item.amount);
                                                setSelectedRank(index + 1);
                                                setSelectedWalletShow(true);
                                                setSelectedListItem({ index: index, flag: true });
                                            }}
                                        >
                                            <div className="flex justify-between w-full">
                                                <div className="inline-flex">
                                                    <p>#{index + 1}-</p>
                                                    <p className="text-white line-clamp-1">
                                                        {
                                                            item.address.slice(0, 4) +
                                                            "..." +
                                                            item.address.substr(item.address.length - 3)
                                                        }
                                                    </p>
                                                </div>
                                                <p className="">{item.amount}</p>
                                                {/* <p className="">{item.amount / 110000000000}%</p> */}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <button
                                className="absolute p-2 rounded-[10px] top-16 right-4 bg-[#1d1921] hover:bg-[#442f4f]"
                                onClick={() => {
                                    setShowWalletList(true);
                                }}
                            >
                                <p className="text-white flex"><span className="hidden sm:block">Wallet&nbsp;</span>Lists</p>
                            </button>
                        </div>
                    )
                }
            </div >
        </div >
    );
}
