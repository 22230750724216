import React from 'react'

import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton,
} from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'

const LoginButton = () => {    
    const wallet = useWallet()
    const { publicKey } = wallet;
    return (
        <div>
            <WalletModalProvider>
                { publicKey ? <WalletDisconnectButton />  : <WalletMultiButton/> }
            </WalletModalProvider>                
        </div>
    )
}

export default LoginButton
