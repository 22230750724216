import { TOKEN_PROGRAM_ID, createTransferInstruction } from "@solana/spl-token";
import { PublicKey, Transaction } from "@solana/web3.js";
import { useState } from "react";
import { convert_to_wei_value_with_decimal, getATAPublicKey } from "../utils";
import LoginButton from "./button";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";


export default function Airdrop() {
  const { publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();

  const adminWallet = publicKey;
  const [airdropList, setList] = useState();
  const [tokenAddress, setTokenAddress] = useState("FKMp8TtXmyTTLbqAwsifLoVNdAyM2hR1aZCbHuAgjwrr");

  const [tokenAddressWarning, setTokenAddressWarning] = useState(false);  
  const [airdropListWarning, setListWarning] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [txHashList, setTxHashList] = useState();

  const onTextChangeHandler = (e) => {
    const data = e.target.value;
    console.log(data);
    setListWarning();
    setList(data);
  }
  const onTokenAddressChangeHandler = (e) => {
    const data = e.target.value;
    setTokenAddressWarning();
    setTokenAddress(data);
  }

  const airdropHandler = async () => {
    if (!adminWallet) return;
    if (!airdropList) {
      setListWarning(true);
      return;
    }
    if (!tokenAddress) {
      setTokenAddressWarning(true)
      return;
    }
    try {
      setTxHashList([])

      setLoading(true)

      const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight },
      } = await connection.getLatestBlockhashAndContext();

      let transaction = new Transaction({
        feePayer: publicKey,
        recentBlockhash: blockhash,
      });
      let txnCounter = 0;
      let airdropListArray;
      try {
        airdropListArray = JSON.parse(airdropList);
      } catch(e) {
        setListWarning(true);
        setLoading(false)
        return;
      }
      const txList = [];
      for (const account of airdropListArray) {
        const { amount, address } = account;
        console.log(tokenAddress, adminWallet)

        const sourceATA = getATAPublicKey(new PublicKey(tokenAddress.toString()), new PublicKey(adminWallet))
        const destinationATA = getATAPublicKey(new PublicKey(tokenAddress), new PublicKey(address))
        const amountInt = convert_to_wei_value_with_decimal(amount, 9);
        console.log(sourceATA.toBase58(), destinationATA.toString())
        transaction.add(
          createTransferInstruction(
            sourceATA,
            destinationATA,
            new PublicKey(adminWallet),
            amountInt,
            [],
            TOKEN_PROGRAM_ID
          )
        )
  
        // Every 15 transactions, we need to run txn because large offset problem would be happened.
        txnCounter++;
        if (txnCounter >= 10) {
          const signature = await sendTransaction(transaction, connection, { minContextSlot });
          await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
          console.log(signature)
          txList.push(signature);
          console.log(
            "To distribute rewards succeed",
            `https://solscan.io/tx/${signature}`
          )
          transaction = new Transaction();
          txnCounter = 0;
        }
      }
      if (txnCounter > 0) {
        console.log(txnCounter)
        const signature = await sendTransaction(transaction, connection, { minContextSlot });
        await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
        console.log(signature)
        txList.push(signature);
        console.log(
          "To distribute rewards succeed",
          `https://solscan.io/tx/${signature}`
        )
      }
      setTxHashList(txList)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
    
  }
  return (
    <div className="w-full h-screen bg-[#0a0a0a]">
      <div className="w-full text-center text-white font-semibold text-[24px] md:text-[48px] p-10 md:p-20">
        Solana SPL-Token Airdrop Helper
      </div>
      <div className="w-full flex flex-col justify-center items-center gap-6 p-4 md:p-10">
        <h2 className="w-full text-white text-xl text-center">Please input your airdrop list</h2>
        <textarea onChange={onTextChangeHandler} value={airdropList} className="w-92 md:w-[600px] h-52"></textarea>
      </div>
      <div className="w-full flex flex-col justify-center items-center gap-6">
        <h2 className="text-white text-xl">Please input your token address</h2>
        <input className="w-92 md:w-[500px] h-12 text-center" disabled value={tokenAddress} onChange={onTokenAddressChangeHandler} />
      </div>
      <div className="w-full flex flex-col justify-center items-center p-6">
        {
          adminWallet && (airdropListWarning || tokenAddressWarning) && (
            <div>
              { airdropListWarning && <p className="text-white">Please input correct airdrop list</p> }
              { tokenAddressWarning && <p className="text-white">Please input correct token address</p> }
            </div>
          )
        }
        { adminWallet ? 
          <button 
            disabled={isLoading}
            onClick={airdropHandler}
            className="px-6 py-2 border-2 border-white text-white"
          >{isLoading? "Process..." :"Airdrop"}</button>
        :
          <LoginButton />
        }
        {
          txHashList && txHashList.length > 0 && (
            <div className="w-full text-center text-white">
              {
                txHashList.map((txHash) => {
                  return (
                    <p key={txHash}><a href={`https://solscan.io/tx/${txHash}`} target="_blank" rel="noreferrer">{txHash}</a></p>
                  )
                })
              }
            </div>
          )
        }
      </div>
    </div>
  )
}