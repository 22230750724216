import { PublicKey } from "@solana/web3.js";

export const NETWORK = "https://api.mainnet-beta.solana.com";
// export const NETWORK = "https://api.devnet.solana.com";


// ----  mainnet-beta ----
export const ALIEN_MINT = new PublicKey("FKMp8TtXmyTTLbqAwsifLoVNdAyM2hR1aZCbHuAgjwrr")
export const STATE_ACCOUNT = new PublicKey('8LjMZLQhQHkpGGa4rJ5cfFNHpXtDvRaWKxGtCFxosb5T');
export const POOL_AUTHORITY = new PublicKey('GHNKxgFq6sjCrepebCo2S8D6SmynzSQ3jdc2YhacJTQz');

// ----  devnet ----
// export const ALIEN_MINT = new PublicKey(
//   "GtbRPKm68688fABPP1E21ZeHbVdCmBz5bqnAqUabfKYU"
// );
// export const STATE_ACCOUNT = new PublicKey('8LjMZLQhQHkpGGa4rJ5cfFNHpXtDvRaWKxGtCFxosb5T');
// export const POOL_AUTHORITY = new PublicKey('GHNKxgFq6sjCrepebCo2S8D6SmynzSQ3jdc2YhacJTQz');