import { useEffect, useState } from "react";
import { Link, Element } from 'react-scroll';
import { Connection, PublicKey, SYSVAR_RENT_PUBKEY, clusterApiUrl } from "@solana/web3.js";
import { AnchorProvider, Program, BN, web3 } from "@coral-xyz/anchor";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { ALIEN_MINT, POOL_AUTHORITY, STATE_ACCOUNT } from "../config";
import SolanaWallet from '../assets/Phantom.webp'
import VideoSrc from '../assets/AIRX_Alien.mp4'
import IconImg from '../assets/icon.png'
import Solana from '../assets/solana.png'
import Telegram from '../assets/telegram.png'
import Twitter from '../assets/twitter.png'
import AlienTokenImg from '../assets/AIRx_Alien_Front_1000.png'
import { getATAPublicKey } from "../utils";
import IDL from "./presale.json";
import { useNavigate } from "react-router-dom";


const network = "https://marty-bkahj8-fast-mainnet.helius-rpc.com/"; // clusterApiUrl('mainnet-beta');
const programID = 'J1g88rh5zyqG17AXAd66MUXTfRMa2sksR1wSDP6Ly3NC';
const price = 2500000;
const opts = {
    preflightCommitment: "processed"
}

const convert_to_wei_value_with_decimal = (val, decimal) => {
    const decimalBN = Math.pow(10, decimal);

    const wei_value = Number(val) * Number(decimalBN);
    return new BN(parseInt(wei_value).toString());
}

export default function HomeScene(props) {
    const [walletAddress, setWalletAddress] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const checkIfWalletIsConnected = async () => {
        try {
            const { solana } = window;

            if (solana) {
                if (solana.isPhantom) {
                    console.log('Phantom wallet found!');
                    const response = await solana.connect({ onlyIfTrusted: true });

                    setWalletAddress(response.publicKey.toString());
                }
            } else {
                console.log('Solana object not found! Get a Phantom Wallet 👻');
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        checkIfWalletIsConnected();
    }, [])

    const connectWallet = async () => {
        const { solana } = window;
        if (solana) {
            const response = await solana.connect();
            // console.log('Connected with Public Key:', response.publicKey.toString());
            setWalletAddress(response.publicKey.toString());
        }
    };
    const onInputChange = (event) => {
        const { value } = event.target;
        setInputValue(value);
    };
    const getProvider = () => {
        const connection = new Connection(network, opts.preflightCommitment);
        const provider = new AnchorProvider(
            connection, window.solana, opts.preflightCommitment,
        );
        return provider;
    }
    // const truncateAddress = (addr) => {
    //     const len = addr.length;
    //     const preTxt = addr.slice(0, 4);
    //     const lastTxt = addr.slice(len-4, len);
    //     return `${preTxt}...${lastTxt}`;
    // }
    const deposit_sol = async () => {
        try {
            if (inputValue) {
                const amount = parseFloat(inputValue);
                if (!isNaN(amount)) {
                    const amountInt = convert_to_wei_value_with_decimal(amount, 9); //  / price
                    const provider = getProvider();
                    const program = new Program(IDL, programID, provider);
                    const user_wallet = new PublicKey(walletAddress);
                    const POOL_ATA = getATAPublicKey(ALIEN_MINT, POOL_AUTHORITY);
                    const ADMIN_ATA = getATAPublicKey(ALIEN_MINT, user_wallet);
                    setLoading(true);
                    console.log(amountInt.toString())
                    await program.methods.depositSol(amountInt).accounts({
                        userAuthority: user_wallet,
                        stateAccount: STATE_ACCOUNT,
                        tokenMint: ALIEN_MINT,
                        userTokenAta: ADMIN_ATA,
                        poolAuthority: POOL_AUTHORITY,
                        poolToken: POOL_ATA,
                        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                        systemProgram: web3.SystemProgram.programId,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        rent: SYSVAR_RENT_PUBKEY
                    }).rpc();
                    setLoading(false);
                    const alienAmount = amount * price;
                    alert(`To purchase ${alienAmount} $Alien is success!`)
                }
            }
        } catch (error) {
            console.log("To purchase $Alien failed", error);
            setLoading(false);
            alert(`To purchase $Alien failed!`)
        }
    }
    const handleDeposit = async () => {
        if (!isLoading) {
            await deposit_sol();
        }
    }

    const goToUFO = () => {
        navigate('/ufo')
    }

    return (
        <div className="w-full bg-[#0a0a0a]">

            <div className="w-full h-[200px] md:h-[400px] xl:h-screen bg-[url('./assets/back1.png')] bg-cover relative">
                <img src={IconImg} className="absolute top-[50px] left-[30px] w-[50px] h-[30px]" alt="alien" />

                <div className="flex md:hidden">
                    <div className="absolute bottom-[10px] left-[30px]">
                        <Link to="buynow" smooth={true} duration={500} className="flex flex-col h-[60px] items-end pr-3 justify-center" >
                            <a className="underline underline-offset-8 cursor-pointer text-[#fff] text-[18px] font-ARLRDBD">Buy now</a>
                        </Link>
                    </div>
                    <div className="absolute bottom-[10px] right-[30px]">
                        <a onClick={goToUFO} className="underline underline-offset-8 cursor-pointer text-[#fff] text-[20px] font-ARLRDBD">UFO</a>
                        <Link to="howto" smooth={true} duration={500} className="flex flex-col h-[60px] items-end pr-3 justify-center" >
                            <a className="underline underline-offset-8 cursor-pointer text-[#fff] text-[18px] font-ARLRDBD">How to</a>
                        </Link>
                    </div>
                </div>

                <div className="hidden md:flex">
                    <div className="absolute top-[50px] right-[30px]">
                        <div className="flex flex-row justify-items-center">
                            <img src={AlienTokenImg} className="w-[50px] h-[50px]" alt="alien" />
                            <Link to="buynow" smooth={true} duration={500}>
                                <div
                                    className="flex items-center justify-center cursor-pointer text-[#fff] w-[160px] h-[50px] text-[20px] font-ARLRDBD bg-[url('./assets/btnBack2.png')] bg-cover"
                                >Buy Now</div>
                            </Link>
                        </div>
                        <div className="flex flex-row items-center justify-around">
                            <a onClick={goToUFO} className="underline underline-offset-8 cursor-pointer text-[#fff] text-[20px] font-ARLRDBD">UFO</a>
                            <Link to="howto" smooth={true} duration={500} className="flex flex-col h-[60px] items-end pr-3 justify-center" >
                                <a className="underline underline-offset-8 cursor-pointer text-[#fff] text-[20px] font-ARLRDBD">How to</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center w-full h-full  font-ARLRDBD text-center">
                <div className="text-[#eee] p-[30px_10px] md:p-[130px_70px] text-[20px] md:text-[35px]">
                    Get ready for a cosmic future WEB3 token
                    <span className="text-primary">
                        &nbsp;$Alien
                    </span> which operates on the Solana blockchain, known for its fast and efficient transactions. This innovative asset blends the excitement of gaming with the cutting-edge world of cryptocurrency. Furthermore, the integration of $Alien into the Aircoins App will take the experience to the next level, as it will be accessible in
                    <span className="text-primary">
                        &nbsp;Augmented Reality
                    </span> (AR) soon!
                </div>
                <video className='w-[300px] h-[300px] md:w-[500px] md:h-[500px]' loop autoPlay muted>
                    <source src={VideoSrc} type='video/mp4' />
                </video>
                <Element name="howto" className="">
                    <p className="text-secondary text-[30px] md:text-[80px] mt-[50px]">
                        $ALIEN PRE-SALE
                    </p>
                </Element>

                <div className="text-[#eee] p-[30px_10px]  md:p-[30px_70px] text-[20px] md:text-[35px]">
                    To give enthusiasts an early opportunity to acquire
                    <span className="text-primary">
                        &nbsp;$Alien
                    </span>, Aircoins is organizing a presale event. This presale offers a unique chance to get ahead in the game by securing these assets before they become widely available.
                    <span className="text-primary">
                        &nbsp;7.7 Billion $Aliens
                    </span>  will be availble for pre-sale.
                </div>

                <Element name="buynow" className="">
                    <p className="text-secondary text-[30px] md:text-[80px] mt-[50px]">
                        How to buy $Alien tokens
                    </p>
                </Element>
                <div className="flex justify-center">
                    <div className="text-[#eee] p-[30px_10px]  md:p-[30px_0px] text-[14px] md:text-[20px] text-left mx-auto">
                        <p className="text-[20px] md:text-[28px]">1.  Prepare a wallet for Solana</p>
                        <p className="">Use Phantom wallet plugin or other wallets that support Solana (SOL)</p>
                        <p className="text-[20px] md:text-[28px]">2.  Have some SOL</p>
                        <p className="">Buy for yourselves some SOL via some reputable CEXs and send them to your wallet</p>
                        <p className="text-[20px] md:text-[28px]">3.  Swap to $Alien tokens</p>
                        <p className="">Choose the Buy link on the website and enter SOL amount to buy</p>
                        <p className="text-[20px] md:text-[28px]">4. Check your wallet to confirm $Alien token purchase on chain and you can confirm by checking <a target="_blank" className="underline" href="https://solscan.io/token/FKMp8TtXmyTTLbqAwsifLoVNdAyM2hR1aZCbHuAgjwrr">solscan</a></p>
                    </div>
                </div>
                <p className="text-third text-[30px] md:text-[50px] py-[20px]">
                    1 SOL = 2,500,000 $Alien
                </p>
            </div>
            <div className=" w-full h-[180px]  gap-[20px]  flex flex-row  justify-center mt-[20px]">
                <div className="flex items-center justify-center w-[160px] h-[50px] md:w-[320px] md:h-[100px] text-[#fff] text-[20px] md:text-[30px] font-ARLRDBD bg-[url('./assets/btnBack2.png')] bg-cover " >
                    <input
                        type="text"
                        placeholder="SOL amount"
                        className="text-center bg-transparent amount border-none outline-none"
                        onChange={onInputChange}
                    />
                </div>
                {walletAddress ?
                    <div
                        onClick={handleDeposit}
                        className="flex items-center justify-center cursor-pointer w-[160px] h-[50px] md:w-[320px] md:h-[100px] text-[#0a0a0a] text-[20px] md:text-[30px] font-ARLRDBD bg-[url('./assets/btnBack1.png')] bg-cover"
                    >
                        {isLoading ? "Processing..." : "Buy $ALIEN"}

                    </div>
                    :
                    <div
                        onClick={connectWallet}
                        className="flex items-center justify-center cursor-pointer w-[160px] h-[50px] md:w-[320px] md:h-[100px] text-[#0a0a0a] text-[20px] md:text-[30px] font-ARLRDBD bg-[url('./assets/btnBack1.png')] bg-cover"
                    >
                        Connect wallet
                    </div>
                }
            </div>
            <div className=" bg-[url('./assets/bg3.png')] bg-right	bg-no-repeat	bg-contain">
                <div className="flex  flex-col items-center ">
                    <img src={Solana} className="w-[250px] md:w-[500px]" alt="alien" />
                    <p className="text-third text-[16px] md:text-[36px] mt-[20px] font-ARLRDBD">
                        Total Supply: 11B $Alien
                    </p>
                </div>
                <div className="flex flex-col items-center  ">
                    <div className="flex flex-row gap-2 font-ROGFONT text-[26px]  md:text-[100px] mt-[20px] ">
                        <p className="text-third">
                            $ALIEN&nbsp;
                        </p>
                        <p className="text-[#fff]">
                            TOKENOMICS
                        </p>
                    </div>
                    <div className="text-[#fff] text-[20px] md:text-[42px] font-ARLRDBD">
                        1<span className="text-sixth">
                            &nbsp;SOL&nbsp;
                        </span> = 2,500,000
                        <span className="text-third ]">
                            &nbsp;$Alien
                        </span>
                    </div>

                    <p className="text-[#fff] font-ARLRDBD text-[20px] md:text-[42px]">
                        <span className="text-third">
                            Pre-Sale:&nbsp;
                        </span>
                        70%
                    </p>
                    <p className="text-[#fff] font-ARLRDBD text-[20px] md:text-[42px]">
                        <span className="text-third">
                            AIRx Swaps:&nbsp;
                        </span>
                        10%
                    </p>
                    <p className="text-[#fff] font-ARLRDBD text-[20px] md:text-[42px]">
                        <span className="text-third">
                            Marketing:&nbsp;
                        </span>
                        10%
                    </p>
                    <p className="text-[#fff] font-ARLRDBD text-[20px] md:text-[42px]">
                        <span className="text-third">
                            Team:&nbsp;
                        </span>
                        10%
                    </p>
                </div>
            </div>
            <div className="flex flex-col items-center w-full h-full  font-ARLRDBD p-[30px_10px] md:p-[130px_70px]">
                <a className="text-fifth text-[18px] md:text-[48px] text-center">
                    Solana (SOL) is a cryptocurrency that was designed to work similarly to and improve upon Ethereum.
                </a>
                <img src={SolanaWallet} className="mt-[20px]" alt="alien" />
                <a href="https://phantom.app" target="_blank" className="text-fourth underline underline-offset-8 text-[18px] md:text-[48px] mt-[20px] md:mt-[60px]">
                    Download Solana Phantom Wallet
                </a>
            </div>

            <div className="flex flex-col items-center w-full h-full font-ARLRDBD text-center">
                <div className="text-[#eee] p-[20px_10px] md:p-[30px_50px] text-[20px] md:text-[35px]">
                    With the creators of Aircoins AR App we bring you     <span className="text-primary">$Alien </span>tokens on the Solana blockchain to be featured in AR and promises to deliver an immersive and interactive gaming experience, allowing players to engage with  <span className="text-primary">$Alien </span> in a dynamic and exciting new way. This blend of blockchain technology, gaming, and AR showcases Aircoins' commitment to innovation in the digital entertainment space.
                </div>
            </div>
            <div className="w-full h-[200px] md:h-[400px] xl:h-screen  bg-[url('./assets/bgImg2.jpg')] bg-cover mt-[20px] md:mt-[40px]">
            </div>
            <div className="flex flex-col items-center w-full h-full font-ARLRDBD px-[10px] pb-[100px]">
                <div className="text-[#eee] mt-[20px] text-[18px] md:text-[40px]">
                    Aircoins App launch and $Alien hunt will start in January 2024
                </div>
                <div className="text-[#eee] mt-[20px] text-[18px] md:text-[40px]">
                    Aircoins
                    <span className="text-primary">
                        &nbsp; Alien  &nbsp;
                    </span>
                    AR Technology
                </div>
                <div className="text-[#eee] underline underline-offset-8 mt-[20px] text-[16px] md:text-[26px]">
                    <a href="https://www.aircoins.io" target="_blank">
                        www.aircoins.io
                    </a>
                </div>
                <div className="flex flex-row items-center justify-center p-10">
                    <a href="https://twitter.com/Aircoins_App" target="_blank">
                        <img src={Twitter} alt="twitter logo" className="w-[60px] h-[60px]" />
                    </a>
                    <a href="https://t.me/aircoins" target="_blank">
                        <img src={Telegram} alt="telegram logo" className="w-[50px] h-[50px]" />
                    </a>
                </div>
            </div>

        </div >
    )
}