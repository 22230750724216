import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import * as buffer from "buffer";
import HomeScene from "../pages/HomeScene";
import BubbleScene
    from "src/pages/BubbleScene";
import Airdrop from "../pages/airdrop";
export default function Router() {
    window.Buffer = buffer.Buffer;
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomeScene />}></Route>
                <Route path="/UFO" element={<BubbleScene />}></Route>
                <Route path="/airdrop" element={<Airdrop />}></Route>
            </Routes>
        </BrowserRouter>
    )
}